import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/public.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import $globalStore from '@/store/global'
import { createFromIconfontCN } from '@ant-design/icons-vue'
let pinia = createPinia();


let app = createApp(App);
app.use(pinia).use(router).use(ElementPlus)
app.component('CustomIcon', createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_4009711_om02xvgpnj.js'
}))

let globalStore = $globalStore();
router.beforeEach((to, from, next)=>{
    if(to.name != 'login' && !globalStore.token){
        next({name: 'login'})
    }else if(to.name == 'login' && globalStore.token){
        next({name: 'home'})
    }else {
        next()
    }

    if(to.name){
        globalStore.menuCurrentItem = to.name;
    }
})
Date.prototype.format = function (){
    let time = this;
    let year = time.getFullYear()
    const month = (time.getMonth() + 1).toString().padStart(2, '0')
    const date = (time.getDate()).toString().padStart(2, '0')
    const hours = (time.getHours()).toString().padStart(2, '0')
    const minute = (time.getMinutes()).toString().padStart(2, '0')
    const second = (time.getSeconds()).toString().padStart(2, '0')
  
    return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
  }
app.mount('#app')