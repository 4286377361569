import { createRouter, createWebHistory } from 'vue-router'


const routes = [
    {
        path: '/login',
        name: 'login',
        component: ()=> import('@/pages/Login')
    },
    {
        path: '/',
        redirect: { name: 'home' }
    },
    {
        path: '/web/core/',
        redirect: { name: 'home' },
        component: ()=> import('@/pages/Admin/Index'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: ()=> import('@/pages/Admin/Home')
            },
            {
                path: 'global',
                name: 'global',
                component: ()=> import('@/pages/Admin/ContentManage/Global')
            },
            {
                path: 'outsource-staff',
                name: 'outsource-staff',
                component: ()=> import('@/pages/Admin/OutsourceStaff')
            },

        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
