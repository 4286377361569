import { defineStore } from 'pinia'

export default defineStore('global', {
    state(){
        return {
            menuCurrentItem: '',
            token: localStorage.getItem('token') || '',
            config: {},
            r: 0
        }
    }
})